import React, { useRef } from "react"
import { graphql, navigate } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TVDemand from "components/sections/tv-demand"
import SectionBackground from "components/section-background"
import Demand360Ad from "components/demand360-ad"
import Title404 from "images/404.svg"
import TitleNotFound from "images/page-not-found.svg"
import IconSearch from "images/icons/search-gray.svg"
import Button from "components/button"
import BgWaves from "images/bg/waves.jpg"
import BasicBackgroundSection from "components/basic-background-section"
import BgCompany from "images/bg/company.jpg"

import { getSharePreviewUrls } from "utils/share-preview"

const Header = styled.div`
  ${tw`pt-24 m:pt-48 l:pt-48 pb-16 m:pb-20 m:pb-24 px-2 flex flex-col justify-center items-center
       content-center mx-auto max-w-s m:max-w-m l:max-w-l`}

  .code {
    width: 183px;

    @media (min-width: 640px) {
      width: 217px;
    }
    @media (min-width: 1200px) {
      width: 272px;
    }
  }

  .name {
    width: 318px;

    @media (min-width: 640px) {
      width: 397px;
    }
    @media (min-width: 1200px) {
      width: 477px;
    }
  }
`

const Content = styled.div`
  ${tw`pt-24 m:pt-40 pb-16 l:pb-40`}
`

const Search = styled.div`
  ${tw`px-2 pb-8 m:pb-12 l:pb-16 flex flex-col justify-center items-center
       content-center mx-auto m:max-w-m l:max-w-l`}

  .title {
    ${tw`font-display text-white text-center pb-6 m:pb-12`}
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 640px) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (min-width: 1024px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .search {
    ${tw`font-italic italic text-grey-5 outline-none w-full`}
    background-color: rgba(255,255,255,1);
    background-image: url(${IconSearch});
    background-position: left 15px top 50%;
    background-repeat: no-repeat;
    background-size: 20px auto;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: black;
    font-size: 15px;
    font-weight: 300;
    height: 48px;
    max-weight: 100% !important;
    padding-left: 42px;
    padding-right: 10px;
    padding-top: 2px;
    transition: all 200ms;
    white-space: nowrap;

    &.active {
      ${tw`text-grey-5 outline-none`}
      background-image: none;
      padding-left: 42px;
    }

    &::placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

const Page404 = ({ data }) => {
  const searchRef = useRef()

  const { page } = data.gcms

  const backgroundStack = [
    `linear-gradient(0deg, rgba(33, 0, 79, 0.7), rgba(33, 0, 79, 0.7))`,
    `url(${BgCompany})`,
  ]

  function gotoSearch() {
    navigate(`/insights?query=${searchRef.current.value}`)
  }

  function keyHandler(event) {
    if (event.key === "Enter") {
      gotoSearch()
    }
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <BasicBackgroundSection
        bgStack={backgroundStack}
        bgPosition="center"
        size="cover"
        repeat="no-repeat"
      >
        <Header>
          <img className="inline-block code" src={Title404} />
          <img className="inline-block name" src={TitleNotFound} />
        </Header>
      </BasicBackgroundSection>
      <SectionBackground
        gradientClass="page-404"
        bg={BgWaves}
        color="#0d0023"
        opacity="0.4"
        repeat="repeat-y"
        size="100% auto"
        bgPosition="center"
      >
        <Content>
          <Search>
            <h1 className="title">Search strategic streaming insights:</h1>
            <div className="flex flex-col m:flex-row items-center w-full m:w-4/5 l:w-1/2">
              <input
                ref={searchRef}
                className="search"
                autoComplete="off"
                placeholder="Try searching: SVOD strategy"
                type="search"
                inputMode="search"
                onKeyDown={keyHandler}
              />
              <div className="hidden m:block m:ml-3">
                <Button className="secondary white-text" onClick={gotoSearch}>
                  Search
                </Button>
              </div>
            </div>
          </Search>
          {data?.allTvDemandJson?.nodes[0]?.markets?.length > 1 && (
            <TVDemand showCTA={false} />
          )}
        </Content>
      </SectionBackground>
      <Demand360Ad />
    </Layout>
  )
}

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/404" }) {
        ...PageData
      }
    }
    allTvDemandJson {
      nodes {
        markets {
          country_name
        }
      }
    }
  }
`

export default Page404
